@import url("https://use.typekit.net/qrz6coy.css");
@import './foundation/foundation';
@import './animate';
@include foundation-everything;

:root {
  --red: #E74336;
  --green: #31A753;
  --blue: #517BBD;
  --orange: #F9BC09;
}

a:hover {
  color: var(--orange);
}

body {
  font-family: "proxima-nova";
  background-color: #000;
  min-height: 100vh;
}

body *{
  color: #fff;
}

.branding h1 {
  margin: 0;
}

.header-wrapper {
  border-bottom: 1px solid rgba(255,255,255,0.4);
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.site-title {
  display: flex;
  align-items: center;
}

h1.site-title {
  font-size: 24px!important;
  width: 100px;
  line-height: 1;
}

span.logo svg {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.nav-main a {
  color: #fff;
}
.nav-main a.trigger {
  display: block;
}

.nav-main a.trigger svg {
  width: 30px;
  height: 30px;
  fill: #fff;
}


.mobile-bg {
  width: 100%;
  min-height: calc(100vh - 97px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.vp {
  position: relative;
  background-color: rgba(0,0,0,0.50);
}

.vp article {
  padding: 1rem;

  position: relative;
  z-index: 10;
}

.vp article header h5 {
  font-weight: 400;
  background-color: rgba(0,0,0,0.25);
}
.absolute-scope {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.vp article header h2 {
  line-height: 1;
}
.button {
  background-color: #000;
  border: 2px solid;
  text-transform: uppercase;
  font-weight: 500;
}

.button.rounded {
  border-radius: 50px;
}

a.button:hover {
  background-color: var(--orange);
  border-color: var(--orange);
  color: #000;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.panel {
  background-color: #000;
  position: relative;
}

.panel-main, .panel.secodary {
  background: rgb(55,55,55);
  background: linear-gradient(-180deg, rgba(55,55,55,1) 0%, rgba(0,0,0,0) 100%);
}
.topgradient {
  position: relative;
  z-index: 10;
}

.topgradient:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  height: 200px;
  top:0;
  left:0;
  right: 0;
  background: rgb(55,55,55);
  background: linear-gradient(-180deg, rgba(55,55,55,1) 0%, rgba(0,0,0,0) 100%);
}
.flex.flex3 > *{
  flex: 1 0 100%;
}

.feature {
  padding: 1rem;
}

.feature p {
  font-weight: 500;
}

.partnership {
  padding: 1rem;
}

.partnership section:first-child  {
  display: flex;
}

.partnership section:first-child  > * {
  align-items: center;
}

@media screen and (min-width: 40em) {
  .vp article header h2 {
    font-size: 4rem;
    font-weight: 800;
  }
  .panel {
    // margin-top: 4rem;
  }

  .partnership section:first-child  {
    display: block;
  }
}
/* Small only */
@media screen and (max-width: 39.9375em) {}

/* Medium and up */
@media screen and (min-width: 40em) {
  .vp article header h5 {
    max-width: 500px;
  }
  .vp article header h2:first-child {
    padding-top: 2rem;
  }

  .vp article {
    min-height: 400px;
  }
  .top {
    position: relative;
    z-index: 1000;
  }
  .gform_wrapper h3.gform_title {
    font-weight: 800;
    display: inline-block;;
  }
}
.people {
  position: relative;
}
/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {
    .flex.flex3 > *{
    flex: 1 0 30%;
  }
  .flex {
    flex-wrap: nowrap;
  }

  .fcc {
    align-items: center;
  }
  .nav-main {
    line-height: 1em;
    text-transform: uppercase;
  }
  .nav-main a {
    padding: 12px 15px;
    position: relative;
  }
  .nav-main a {
    color: #fff;
    text-decoration: none;
  }
  .nav-main a:focus,
  .nav-main a:hover {
    color: var(--orange);
  }

  .nav-main a.current-menu-item {
    border-bottom: 1px solid var(--orange);
    font-weight: 700;
  }
  .vp article {
    min-height: 400px;
  }
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}


// OVERWRITES

.gform_wrapper div.validation_error,
.validation_message

{
  background-color: var(--orange);
  color: #fff;
  font-weight: 700;
  padding: 6px;
  margin-bottom: 10px;
  // border-color: var(--orange);
}
.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent;
}
body.contact .gform_wrapper {
  padding-top: 30vh;
}


input[type="text"],textarea {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  display: block;
  width: 100%;
}
input[type="submit"] {
  background-color:transparent;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--yellow);
  border-radius: 50px;
}
.gform_wrapper .gfield_required {
  font-size: 125%;
  color: var(--orange);
}

.gform_body ul {
  margin: 0;
  padding: 0;
  list-style: none
}